import NextErrorComponent from 'next/error';
import ErrorPage from '../pages/error/[slug]';

const Page = ({ statusCode }: any): JSX.Element =>
    <ErrorPage code={ statusCode.toString() } />
;

Page.getInitialProps = NextErrorComponent.getInitialProps;

export default Page;
